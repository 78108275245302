<template>
  <div class="serve">
      <img class="serveImg" src="@/assets/serve/core_img.png" alt="">
      <div class="navbar">
          <div class="navbarItem"  @click="navChoose(0)" v-scroll-to="'#introduce'">
              <p class="navbarItemTitle" :class="{'te':choose.item == 0}">福利店介绍</p>
              <div v-if="choose.item == 0" class="navbarItemLine"></div>
          </div>
           <div class="navbarItem"  @click="navChoose(1)" v-scroll-to="'#feature'">
              <p class="navbarItemTitle" :class="{'te':choose.item == 1}">福利店特色</p>
              <div v-if="choose.item == 1" class="navbarItemLine"></div>
          </div>
           <div class="navbarItem"  @click="navChoose(2)" v-scroll-to="'#support'">
              <p class="navbarItemTitle" :class="{'te':choose.item == 2}">技术与数据</p>
              <div v-if="choose.item == 2" class="navbarItemLine"></div>
          </div>
           <div class="navbarItem"  @click="navChoose(3)" v-scroll-to="'#serve'">
              <p class="navbarItemTitle" :class="{'te':choose.item == 3}">服务人群</p>
              <div v-if="choose.item == 3" class="navbarItemLine"></div>
          </div>
           <div class="navbarItem"  @click="navChoose(4)" v-scroll-to="'#withdraw'">
              <p class="navbarItemTitle" :class="{'te':choose.item == 4}">提现步骤</p>
              <div v-if="choose.item == 4" class="navbarItemLine"></div>
          </div>
           <div class="navbarItem"  @click="navChoose(5)">
              <p class="navbarItemTitle" :class="{'te':choose.item == 5}">合作企业</p>
              <div v-if="choose.item == 5" class="navbarItemLine"></div>
          </div>
      </div>
      <div class="introduce" id="introduce">
          <div class="module_top">
              <p class="module_title">什么是企业福利店？</p>
              <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
          </div>
          <p class="introduceContent">福利店是一个免费为企业搭建的一站式员工消费优惠平台，员工通过平台去淘宝、京东、美团、饿了么等合作电商消费时会得到优惠券，当消费完成后平台会从商家得到一定比例的现金回馈，平台再将现金回馈分享给员工以及企业。企业福利店，是以企业的形象展现在员工面前， 可以帮助企业文化建设，树立企业形象，减少员工流失率，增加员工的忠诚度和凝聚力，打造优质雇主品牌。企业福利店，是一个购物优惠现金回馈平台，能够让员工企业获得现金回馈，增加企业收益。</p>
      </div>
      <div class="feature" id="feature">
          <div class="module_top">
              <p class="module_title">福利店特色</p>
              <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
          </div>
          <p class="introduceContent">剁手记致力于给您打造一款属于您的企业福利店，为员工谋造福利的同时，更能从这些福利中创造更有价值的企业福利，员工的快乐消费，帮助企业获得更多利润。</p>
          <div class="featureBot">
              <div class="featureBotItem">
                  <img  src="@/assets/serve/freeUse.png" alt="">
              </div>
              <div class="featureBotItem">
                  <img src="@/assets/serve/figure.png" alt="">
              </div>
              <div class="featureBotItem">
                  <img src="@/assets/serve/income.png" alt="">
              </div>
              <div class="featureBotItem">
                  <img src="@/assets/serve/earnings.png" alt="">
              </div>
          </div>
      </div>
      <div class="support" id="support">
           <div class="module_top">
              <p class="module_title">技术与数据支持</p>
              <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
          </div>
          <div class="bot">
              <div class="left">
                  <div class="item">
                      <div class="line">
                      </div>
                      <div class="content">
                          <p class="title">我们的团队</p>
                          <p class="text">面向企业级应用研发的 UI 设计语言与前端技术实现。自主研发+专业团队，满足企业个性化定制需求。强大的技术支持，严密的风控系统，保障平台安全运营   </p>
                      </div>
                  </div>
                  <div class="item">
                      <div class="line">
                      </div>
                      <div class="content">
                          <p class="title">技术支持</p>
                          <p class="text">支持公众号、API、H5端口、系统内嵌。灵活多变的平台接入方式，可实现嵌入微信公众号、企业微信、企业OA、企业官网、企业内部APP等多个平台</p>
                      </div>
                  </div>
                  <div class="item">
                      <div class="line">
                      </div>
                      <div class="content">
                          <p class="title">数据服务</p>
                          <p class="text">通过和合作客户数据对比，丰富的使用场景，深受员工喜爱；有效增加公众号的使用率以及点击率</p>
                      </div>
                  </div>
               
              </div>
              <div class="right">
                  <div class="top">
                      <img src="@/assets/serve/team.png" alt="">
                      <img src="@/assets/serve/support.png" alt="">
                  </div>
                  <img class="bottom" src="@/assets/serve/data.png" alt="">
              </div>
          </div>
      </div>
      <div class="income" id="serve">
          <div class="module_top">
              <p class="module_title">企业福利店奖励及收益</p>
              <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
          </div>
          <div class="incomeBot">
              <img class="incomeBotImg"  src="@/assets/serve/app_img.png" alt="">
              <div class="right">
                  <div class="top">
                      <p class="title">员工奖励提现</p>
                      <p class="content">员工消费完成后即可得到奖励，可以直接通过支付宝提现</p>
                      <p class="content">如一个员工每天点2次外卖，每次得2元左右提现红包，一个月光点外卖就能得120元，还不包括通过优惠券节约的费用以及其他购物奖励，活动奖励。</p>
                  </div>
                  <div class="bot">
                      <p class="title">企业获得收益</p>
                      <p class="content">企业将得到员工奖励的30%，如一个员工得120元则企业得36元，那么一个百人企业一个月就能得到3,600元，上千人的企业一个月就是36,000元，员工越多，得利越多。</p>
                      <p class="action">注意：这笔收益是企业不需要投入任何成本就能得到的。</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="withDraw" id="withdraw">
            <div class="module_top">
                <p class="module_title">企业提现</p>
                <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
            </div>
            <div class="withDrawBot">
                <div class="withDrawBotItem">
                    <p class="withDrawBotItemTitle">01/寻找提现入口</p>
                    <p class="withDrawBotItemContent">进入我的页面，点击立即提现，查看订单详情</p>
                    <img class="withDrawBotItemImg" src="@/assets/serve/withDraw1.png" alt="">
                </div>
                <div class="withDrawBotItem">
                    <p class="withDrawBotItemTitle">02/进入收益详情页</p>
                    <p class="withDrawBotItemContent">可查看每笔收入来源及金额，包含企业订单及团队订单</p>
                    <img class="withDrawBotItemImg" src="@/assets/serve/withDraw2.png" alt="">
                </div>
                <div class="withDrawBotItem">
                    <p class="withDrawBotItemTitle">03/提现</p>
                    <p class="withDrawBotItemContent">每月26日之后可一次性将企业收益、网店收益及奖励一起提现</p>
                    <img class="withDrawBotItemImg" src="@/assets/serve/withDraw3.png" alt="">
                </div>
                <div class="withDrawBotItem">
                    <p class="withDrawBotItemTitle">04/完成提现</p>
                    <p class="withDrawBotItemContent">提现成功后，将发票寄往我司，审核通过后将转入您银行账号</p>
                    <img class="withDrawBotItemImg" src="@/assets/serve/withDraw4.png" alt="">
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
     setup(){
        const choose = reactive({
                item:0
            })
            return{
                choose
            } 
        } ,
        methods:{
            navChoose(num){
                this.choose.item = num
            }
        }
}

</script>
<style  scoped>
.serveImg{
    width:100%;
    height:3.2rem;
    display: block;
}
.navbar{
    width:100%;
    height:0.6rem;
    border: 0.01rem solid #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;

}
.navbarItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0 0.4rem;
}
.navbarItemTitle{
    height: 0.22rem;
    font-size:0.16rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.22rem;
}
.navbarItemLine{
    margin-top:0.03rem;
     display: inline-block;
    width: 0.2rem;
    height: 0.04rem;
    background: #F70D08;
    border-radius: 0.03rem;
}
.module_top{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.introduce{
    width:100%;
    height:2.97rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:0.6rem;
    box-sizing: border-box;
}
.module_title{
    height: 0.4rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height:0.4rem;
}
.icon{
    width:0.2rem;
    height:0.06rem;
    display: block ;
}
.introduceContent{
    width:11rem;
    font-size: 0.14rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.3rem;
    text-align: center;
    margin-top:0.2rem;
}
.feature{
     width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FBFBFB;
    padding-top: 0.6rem;
    box-sizing: border-box;

}
.featureBot{
    margin-top:0.76rem;
    height:4.5rem;
    display: flex;
    justify-content: center;
    margin-bottom:0.83rem ;
}
.featureBotItem > img{
    display: block;
    width:3.2rem;
    height: 4.5rem;
}
.support {
    margin-bottom: 1.27rem;
    padding-top: 0.6rem;
    box-sizing: border-box;
}
.support .bot{
    display: flex;
    justify-content: center;
    height:4.7rem;
    margin-top:0.66rem;
}
.support .bot .left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}
.support .bot .left .item{
    display: flex; 
    align-items: center;
}
.support .bot .left .item .line{
    width: 0.04rem;
    height: 0.85rem;
    background: linear-gradient(152deg, #FF6725 0%, #F62B15 100%);
    border-radius: 0.03rem;
    margin-right:0.15rem;
}
.support .bot .left .item .content{
    text-align: left;
    width:5.67rem;
}
.support .bot .left .item .content .title{
    height: 0.36rem;
    font-size: 0.2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.36rem;
    margin-bottom:0.03rem
}
.support .bot .left .item .content .text{
    height:0.6rem;
    font-size: 0.14rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.3rem;
}
.support .bot .right{
    width:7.1rem;
    height: 4.7rem;
    margin-left:0.46rem;
}
.support .bot .right .top{
    display: flex;

}
.support .bot .right .top img{
    width:3.55rem;
    height: 2.34rem;
}
.support .bot .right .bottom{
    width:7.1rem;
    display: block;
    height: 2.34rem;
}
.income{
    width:100%;
    height:7.68rem;
    background: #F7F7F7;
    box-sizing: border-box;
     padding-top: 0.6rem;
    box-sizing: border-box;
}
.income .incomeBot{
    display: flex;
    justify-content: center;
    align-items: center;
}
 .income .incomeBot .incomeBotImg{
     width:5.54rem;
     height: 6.53rem;
 }
  .income .incomeBot .right {
     text-align: left;
     width:5.1rem;
     margin-left: 1.3rem;
 }
 .income .incomeBot .right .top{
     margin-bottom: 0.5rem;
 }
 .income .incomeBot .right .title{
     height: 0.36rem;
    font-size: 0.2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.36rem;
 }
 .income .incomeBot .right .content{
     font-size: 0.14rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.3rem;
 }
  .income .incomeBot .right .bot .action{
    font-size: 0.14rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F62B15;
    line-height: 0.3rem;
 }
 .withDraw{
    height:7.7rem;
    width:100%;
    padding-top:0.6rem;
    box-sizing: border-box;
 }
 .withDraw .withDrawBot{
     display: flex;
     justify-content: center;
 }
 .withDraw .withDrawBot .withDrawBotItem{
     text-align: center;
     margin:0 0.44rem;
 }
 .withDraw .withDrawBot .withDrawBotItem .withDrawBotItemTitle{
    height: 0.22rem;
    font-size: 0.16rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.22rem;
    margin: 0.27rem auto 0;
 }
 .withDraw .withDrawBot .withDrawBotItem .withDrawBotItemContent{
    width: 1.8rem;
    font-size: 0.12rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.17rem;
    margin:0.11rem auto 0.25rem;
 }
 .withDraw .withDrawBot .withDrawBotItem .withDrawBotItemImg{
     width:2.55rem;
     height: 4.55rem;
 }
 .te{
     color: #F70D08 !important;
 }
</style>